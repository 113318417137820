import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from "@remix-run/react";
import clsx from "clsx";
import { rootAuthLoader } from "@clerk/remix/ssr.server";

import { dark } from "@clerk/themes";
import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/cloudflare";

import { Toaster } from "@/components/ui/toaster";
import { FullContentLoading } from "@/components/gen-feedback";
import { useTheme } from "@/lib/theme";

import styles from "./globals.css?url";
import {
  ClerkApp,
  ClerkLoaded,
  ClerkLoading,
  useOrganization,
  useUser,
} from "@clerk/remix";
import { identityForUser } from "~/routes/api.identity";
import { toError } from "@reconfigured/utils";
import posthog from "posthog-js";
import { useIntercom } from "./lib/use-intercom";

export const links: LinksFunction = () => [{ rel: "stylesheet", href: styles }];

export const meta: MetaFunction = () => {
  return [
    { title: "reconfigured" },
    {
      name: "description",
      content: "The app that makes data asks fly by faster than ever before.",
    },
  ];
};

export const loader = (args: LoaderFunctionArgs) => {
  return rootAuthLoader(args, async ({ request }) => {
    const identityData = await identityForUser({
      INTERCOM_HMAC: args.context.cloudflare.env.INTERCOM_HMAC,
    })({ userId: request.auth.userId, orgId: request.auth.orgId ?? null });

    return {
      data: {
        identityData,
        env: {
          intercomAppId: args.context.cloudflare.env.INTERCOM_APP_ID,
        },
      },
      error: null,
    };
  });
};

export const Layout = ({
  children,
}: {
  readonly children: React.ReactNode;
}) => {
  const [theme] = useTheme();
  // TODO: something something with the hydartion errors and remix
  // and all that jazz. Perhaps some hydration fallbacks and ids.
  return (
    <html className={clsx(theme)} lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <Meta />
        <Links />
      </head>
      <body className="h-screen">
        {children}
        <Toaster />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
};

const usePostHogInit = () => {
  const { user, isLoaded: userLoaded } = useUser();
  const { organization, isLoaded: orgLoaded } = useOrganization();
  if (!userLoaded || !orgLoaded) return;

  if (organization) {
    posthog.group("organization", organization.id, {
      name: organization.name,
    });
  }
  if (user) {
    posthog.identify(user.id, {
      name: user.fullName,
    });
  } else {
    posthog.reset();
  }
};

const AppWithEffects = () => {
  useIntercom();
  usePostHogInit();
  return <Outlet />;
};

const App = () => {
  return (
    <>
      <ClerkLoaded>
        <AppWithEffects />
      </ClerkLoaded>
      <ClerkLoading>
        <FullContentLoading />
      </ClerkLoading>
    </>
  );
};

const MainApp = () => {
  const [theme] = useTheme();

  const config: Parameters<typeof ClerkApp>[1] = {
    appearance: {
      layout: {
        // Could embed these to our own website at some point
        termsPageUrl: "https://www.iubenda.com/terms-and-conditions/28140397",
        privacyPageUrl:
          "https://www.iubenda.com/privacy-policy/28140397/full-legal",
      },
      ...(theme === "dark"
        ? {
            baseTheme: dark,
          }
        : {}),
    },
  };

  return ClerkApp(App, config)();
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  const err = toError(error);
  return (
    <div className="bg-background -mt-32 flex min-h-screen flex-col items-center justify-center p-4 text-center">
      <h1 className="text-2xl font-bold text-red-700">
        Oops! Something went wrong.
      </h1>
      <p className="mt-4">
        We&apos;re sorry, but an unexpected error has occurred. Our team has
        been notified.
      </p>

      <div className="mt-6">
        <Link
          className="bg-primary ml-4 rounded-xl px-4 py-2 font-bold text-white hover:bg-green-700"
          reloadDocument
          to="/"
        >
          Back Home
        </Link>

        <details className="mt-4 text-left">
          <summary className="font-semibold">
            Error Details for the nerdy ones
          </summary>
          <pre className="bg-muted mt-2 overflow-x-auto rounded p-2">
            {JSON.stringify(
              {
                name: err.name,
                message: err.message,
              },
              undefined,
              2,
            )}
          </pre>
        </details>
      </div>
    </div>
  );
};

export default MainApp;
