import { useOrganization, useUser } from "@clerk/remix";
import { Intercom } from "@intercom/messenger-js-sdk";
import type { InitType } from "@intercom/messenger-js-sdk/dist/types";
import { clerkIdToReconfId } from "@reconfigured/shared-model";
import { useRouteLoaderData } from "@remix-run/react";
import { useEffect } from "react";
import type { loader } from "~/root";

export const useIntercom = () => {
  const routeLoaderData = useRouteLoaderData<typeof loader>("root");
  const { user } = useUser();
  const { organization } = useOrganization();
  console.log("Intercom init render");

  useEffect(() => {
    console.log("Intercom init effect");
    if (routeLoaderData?.data) {
      const intercomInit: InitType = {
        app_id: routeLoaderData.data.env.intercomAppId,
      };
      if (routeLoaderData.data.identityData.userId) {
        intercomInit.user_id = routeLoaderData.data.identityData.userId;
        intercomInit.user_hash =
          routeLoaderData.data.identityData.intercomUserIdHash;
      }
      if (user?.fullName) {
        intercomInit.name = user.fullName;
      }
      if (user?.primaryEmailAddress) {
        intercomInit.email = user.primaryEmailAddress.emailAddress;
      }
      if (user?.imageUrl) {
        intercomInit.avatar = user.imageUrl;
      }
      if (user?.createdAt) {
        intercomInit.created_at = Math.floor(user.createdAt.getTime() / 1000);
      }
      if (organization) {
        intercomInit.company = {
          id: clerkIdToReconfId(organization.id),
          name: organization.name,
          created_at: Math.floor(organization.createdAt.getTime() / 1000),
        };
      }
      Intercom(intercomInit);
    } else {
      console.error("No route loader data!", routeLoaderData?.error);
    }
  }, [routeLoaderData, user, organization]);
};
